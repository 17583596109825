import {Injectable, Component} from "@angular/core";
import {Router, ActivatedRoute} from "@angular/router";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {environment} from "../../../environments/environment";
import moment from "moment";
import {MessageService} from 'primeng/api';
interface Window {
  Echo: any;
}

@Injectable()
export class GeneralServices {
  private subject = new Subject<any>();

  public add_customer: Subject<any>;
  public plan_details: any;
  addCustomer$ = null;
  isOpenSource: any = new BehaviorSubject<boolean>(false);
  isOpenNotification: any = new BehaviorSubject<boolean>(false);
  channel: any;

  public sharedUserPermission: any = {
    contacts: false,
    calendar: false,
    tasks: false,
    marketing: false,
    reports: false,
    settings: false,
    setup: false,
    addNewStaff: false,
    staffAttendance: false,
    appIntegrations: false,
    rolesAndPrivileges: false,
    accountAndBilling: false,
    inbox: true,
    websiteBuilder: true
  };

  public userPermission = new Subject<any>();
  public isSetAllCookies = new BehaviorSubject<boolean>(null);

  constructor(public snackBar : MatSnackBar, private messageService: MessageService) {
    this.add_customer = new Subject();
    // let config = new MatSnackBarConfig();
    // config. = ['custom-class'];
    this.addCustomer$ = this.add_customer.asObservable();
  }

  sendMessage(message : string) {
    this.subject.next({flag: message});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  setAllCookiesStatus(val: boolean) {
    this.isSetAllCookies.next(val)
  }

  setOpenSource(deletePayload: { isOpen: boolean, subTitle?: string, confirmCallback: Function }) {
    this.isOpenSource.next(deletePayload);
  }

  setNotificationPanel(isOpen: boolean) {
    this.isOpenNotification.next(isOpen);
  }

  // SETUP WEBSOCKET CHANNEL

  // GENERAL FUNCTIONS USEFUL FORM APP DOM MANIPULATION

  /* 1.0 SWITCH TOGGLE */
  toggleCustomSwitch(ev : any) {
    let state = ev.target.value;
    if (state == "active") {
      status = "inactive";
    }
    if (state == "inactive") {
      status = "active";
    }
    if (state == "true") {
      status = "false";
      alert("hello");
    }
    if (state == "false") {
      status = "true";
    }
    ev.target.value = state;
  }

  setUserPermission(data){
    this.userPermission.next(data);
  }

  /* 2.0 INPUT FIELD VALIDATY */
  isFilled(e) {
    if (e.target.value != "") {
      e.target.classList.add("active");
      if (e.target.parentElement.classList.contains("validate-field")) {
        if (e.target.checkValidity()) {
          e.target.classList.remove("invalid");
          e.target.classList.add("valid");
        } else {
          e.target.classList.remove("valid");
          e.target.classList.add("invalid");
        }
      }
    } else {
      e.target.classList.remove("invalid", "valid", "active");
    }
  }

  /* 3.0 TIC TAC TOGGLE */
  toggleCustomTicTac(ev : any) {
    var container = ev.target.parentElement.parentElement;
    var child = container.children;
    var selec = ev.target.parentElement.classList.contains("selected")
      ? true
      : false;
    // for (var i = 0; i< child.length; i++){
    // 	if(child[i].classList.contains('selected')){
    // 		child[i].classList.remove("selected");
    // 		child[i].children[1].checked=false;
    // 		child[i].children[1].removeAttribute("checked");
    //  }
    // }
    if (!selec) {
      ev.target.parentElement.classList.add("selected");
      ev.target.setAttribute("checked", "true");
      ev.target.checked = true;
    }
  }

  /* 4.0 TOAST FOR NOTIFICATIONS */
  // Primeng Toast
  openToast(msg: string, time: number, state: 'success' | 'error' | 'warn' | 'info' = 'warn', showCross: boolean = true) {
    this.messageService.add({ severity: state, summary: msg, life: time, closable: showCross});
  }

  /* 5.0 SMALL TOAST FOR MOBILE NOTIFICATIONS */
  openSmallToast(
    msg, time, state
    ?) {
    let snackBarRef = this.snackBar.open(msg, "  ", {
      duration: time,
      panelClass: ["snackbar-small", state]
    });
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }

  /* 6.0 OBSERVABLE SUBJECT FUNCTION FOR ADD CUSTOMER POPUP */
  addCustomer(bool) {
    this.add_customer.next(bool);
  }

  /* 7.0 INCLUDE CENTER IN HTTP REQUEST FOR AUTHORIZED REQUESTS */
  includeCenter() {
    var center_id = JSON.parse(localStorage.getItem("currentUser"))?.center_id;
    if(center_id) {
      var result = "?center_id=" + center_id;
      return result;
    } else {
      return null;
    }
  }

  getCurrency() {
    const currency = JSON.parse(localStorage.getItem('localization')).currency.symbol;
    return currency;
  }

  /* 8.0 BREADCRUMB APPEARANCE BASED ON SCROLL */
  breadcrumbLastScrollTop: number = 0;
  breadcrumbToggle(e) {
    var breadcrumbHeight = e.target.getElementsByClassName("breadcrumb")[0].offsetHeight;
    var st = e.target.scrollTop;
    var delta = 5;

    if (Math.abs(this.breadcrumbLastScrollTop - st) <= delta)
      return;

    if (st > this.breadcrumbLastScrollTop && st > breadcrumbHeight) {
      e.target.getElementsByClassName("breadcrumb")[0].classList.add("hidden");
    } else {
      e.target.getElementsByClassName("breadcrumb")[0].classList.remove("hidden");
    }

    this.breadcrumbLastScrollTop = st;
  }

  // CUSTOM JQUERY LIBRARY

  /* GET LIST OF ALL PARENT NODES OF ELEMENT */
  parents(el
  : any) {
    let current = el,
      list = [];
    while (current.parentElement != null && current.parentElement != document.documentElement) {
      list.push(current.parentElement);
      current = current.parentElement;
    }
    return list;
  }

  /* GET CLOSEST ELEMENT BY TAG NAME (BUBBLING) OF ELEMENT */
  closestTag(el, cls) {
    while ((el = el.parentElement) && !(el.nodeName == cls))
    ;
    return el;
  }

  /* GET CLOSEST ELEMENT BY CLASS NAME (BUBBLING) OF ELEMENT */
  closestClass(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls))
    ;
    return el;
  }

  /* INDEX OF AN ELEMENT WRT PARENT ELEMENT */
  index(el) {
    var sib = el.parentNode.childNodes;
    var n = 0;
    for (var i = 0; i < sib.length; i++) {
      if (sib[i] == el)
        return n;
      if (sib[i].nodeType == 1)
        n++;
      }
    return -1;
  }

  /* GROUP ARRAY BY A PROPERTY */
  groupBy(collection, property) {
    var i = 0,
      val,
      index,
      values = [],
      result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1)
        result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  /* REMOVE NULL VALUE KEYS FROM OBJECT */
  removeEmpty(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === "object")
        this.removeEmpty(obj[key]);
      else if (obj[key] == null || obj[key] === "")
        delete obj[key];
      }
    );
    return obj;
  }

  /* DECODE BASE 64 */
  decodeBase64(s) {
    var e = {},
      i,
      b = 0,
      c,
      x,
      l = 0,
      a,
      r = "",
      w = String.fromCharCode,
      L = s.length;
    var A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    for (i = 0; i < 64; i++) {
      e[A.charAt(i)] = i;
    }
    for (x = 0; x < L; x++) {
      c = e[s.charAt(x)];
      b = (b << 6) + c;
      l += 6;
      while (l >= 8) {
        ((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a));
      }
    }
    return r;
  }

  /* INSERT TEXT AT CURSOR POINTER */
  insertAtCursor(el, teXt) {
    var start,
      end;
    if (el.selectionStart || el.selectionStart == "0") {
      var startPos = el.selectionStart;
      var endPos = el.selectionEnd;
      el.value = el.value.substring(0, startPos) + teXt + el.value.substring(endPos, el.value.length);
      start = startPos + teXt.length;
      end = startPos + teXt.length;
    } else {
      el.value += teXt;
      start = teXt.length;
      end = teXt.length;
    }
    this.setSelectionRange(el, start, end);
  }
  setSelectionRange(input, selectionStart, selectionEnd) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd("character", selectionEnd);
      range.moveStart("character", selectionStart);
      range.select();
    }
  }

  /* CHANGE TO IN THE ISO FORMAT REQUIRED BY BACKEND */
  convertDateToISOFormat(date) {
    date = new Date(date);
    function pad(n) {
      return n < 10
        ? "0" + n
        : n;
    }
    var result = {};
    result["date"] = date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate());
    result["time"] = pad(date.getHours()) + ":" + pad(date.getMinutes()) + ":" + pad(date.getSeconds());
    return result;
  }

  /* CONSOLE LOG IN DEV MODE */
  console(item) {
    !environment.production
      ? console.log(item)
      : "";
  }

  /* SCROLL TO SPECIFIED INVALID INPUT ELEMENT IN FORM */
  scrolltoinvalid(el) {
    // scroll to input element's (using name attr) parent i.e. 'form-field'
    if(document.getElementsByName(el)[0]) {
      document.getElementsByName(el)[0].parentElement.scrollIntoView({behavior: "smooth", block: "start"});
    }
  }

  calculateTax(TR, price, is_tax_inclusive = false) {
    let taxRate = TR / 100;
    if (is_tax_inclusive) {
      return parseFloat((taxRate * price / (1 + taxRate)).toFixed(2));
    }
    return parseFloat((taxRate * price).toFixed(2));
  }

  //check mobile login
  // checkDevice() {
  //   // const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   console.log('navigator.userAgentData',navigator.userAgent);
  //   if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  checkDevice(): boolean {
    const userAgent = navigator.userAgent;

    const isTablet = /iPad/i.test(userAgent) ||
      (/Android/i.test(userAgent) && !/Mobile/i.test(userAgent)) ||
      /Tablet/i.test(userAgent) ||
      /Kindle/i.test(userAgent) ||
      /Silk/i.test(userAgent);

    const isMobile = /Android/i.test(userAgent) && /Mobile/i.test(userAgent) ||
      /iPhone/i.test(userAgent) ||
      /iPod/i.test(userAgent) ||
      /BlackBerry/i.test(userAgent) ||
      /Windows Phone/i.test(userAgent);

    if (isTablet) {
      return false;
    } else if (isMobile) {
      return true;
    } else {
      return false; // Default behavior if neither tablet nor mobile
    }
  }

  secToMin(sec) {

    return  moment.utc(sec*1000).format('HH:mm:ss')

  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  }
  // Capitalize only the first letter of a string
  capitalizeFirstLetter(str: string) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }

  /**
   * Check if character is unicode
   * @param stringToTest test character
   * @returns whether the chracter is unicode
   */
  containsUnicode(stringToTest: string) {
    return /[^\u0000-\u00ff]/.test(stringToTest);
  }

  /**
   * Returns first character or emoji
   * @param name tagname
   * @returns first character or emoji
   */
  getFirstCharacterOrEmoji(name: string) {
    return this.containsUnicode(name.slice(0, 1))
      ? String.fromCodePoint(
          parseInt(name.codePointAt(0)?.toString(16), 16) ?? 10067
        )
      : name.slice(0, 1).toUpperCase(); 
  }

  setCookie (name, value) {
    const domain = ".bookeeapp.com";
    const path = "/";
    document.cookie = `${name}=${value}; domain=${domain}; path=${path}`;
  };

  getCookie(name: string): string | null {
    const cookieName = name + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return null;
  }

  setV3Cookies () {
    let currentUser
    let localization
    let client
    try {
      currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : null;
      localization = localStorage.getItem("localization") ? JSON.parse(localStorage.getItem("localization")) : null;
      client = localStorage.getItem("client") ? JSON.parse(localStorage.getItem("client")) : null;
      
    } catch (error) {
      console.error("Error parsing localization value:", error);
    }

    currentUser?.token && this.setCookie("token", currentUser.token);
    currentUser?.center_id &&
      this.setCookie("center_id", currentUser.center_id);
    localization?.timezone &&
      this.setCookie("timezone", localization?.timezone);
    localization?.country &&
      this.setCookie("country", JSON.stringify({
        name: localization.country.name,
        isd_code: localization.country.isd_code,
        iso_code_2: localization.country.iso_code_2,
      }));
    currentUser?.name && this.setCookie("user_name", currentUser.name);
    currentUser?.email && this.setCookie("user_email", currentUser.email);
    currentUser?.businessName &&
      this.setCookie("user_business", currentUser.businessName);
    client?.client_url && this.setCookie("business_id", client.client_url);
  }

  clearCookies() {
    const domain = ".bookeeapp.com";
    const path = "/";
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
      const trimmedCookie = cookie.trim();
      const eqPos = trimmedCookie.indexOf("=");
      const name = eqPos > -1 ? trimmedCookie.substring(0, eqPos) : trimmedCookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${domain};path=${path}`;
    }
  }

  roundToThreeDigits(num: number) {
    return Math.round((num + Number.EPSILON) * 1000) / 1000;
  }

  checkIfValidNaturalNumber(num: number) {
    return num && Number.isInteger(num) && num > 0;
  }

  getInstallmentAmount(total = 0, initial = 0, numInstallments: number) {
    const maxInstallments = 99
    if (!this.checkIfValidNaturalNumber(numInstallments)) {
      this.openToast(
        "Number of installments must be valid and greater than 0",
        2000,
        "error"
      );
      return
    } else if (numInstallments > maxInstallments) {
      this.openToast(
        "Number of installments must be lesser than 99",
        2000,
        "error"
      );
      return;
    } else if (total <= initial) {
      this.openToast(
        "Initial payment cannot be greater than or equal to the total amount",
        2000,
        "error"
      );
      return
    } else if (initial <= 0) {
      this.openToast(
        "Initial payment cannot be less than or equal to 0",
        2000,
        "error"
      );
      return
    } else if (total <= 0) {
      this.openToast(
        "Total amount cannot be less than or equal to 0",
        2000,
        "error"
      );
      return
    }

    return this.roundToThreeDigits((total - initial) / numInstallments);
  }

  generatePaymentPlan(total: number, initial = 0, numInstallments: number, duration: number, durationType: string) {
    if (!this.checkIfValidNaturalNumber(numInstallments) || total <= initial || !this.checkIfValidNaturalNumber(duration) || initial < 0 || total <= 0) {
      if (!this.checkIfValidNaturalNumber(duration)) {
        this.openToast(
          "Duration must be valid and greater than 0",
          2000,
          "error"
        );
      } 
      return;
    }
    const paymentPlan = [];
    const currency = this.getCurrency();
    const installmentAmount = this.getInstallmentAmount(
      total,
      initial,
      numInstallments
    );
    let installmentDate = "Immediately";
    let installment = {
      amount: currency + " " + (initial ?? 0),
      date: installmentDate,
      installmentNumber: "Initial Payment",
    };
    paymentPlan.push(installment);
    for (let i = 1; i <= numInstallments; i++) {
      installmentDate = duration * i +
        this.generateOrdinalSuffix(duration * i) +
        " " +
        durationType;
      installment = {
        amount: currency + " " + installmentAmount,
        date: installmentDate,
        installmentNumber: `Installment ${i}/${numInstallments}`,
      };
      paymentPlan.push(installment);
    }
    return paymentPlan;
  }

  generateOrdinalSuffix(number: number) {
    if (number % 100 >= 11 && number % 100 <= 13) {
      return "th";
    }
    const lastDigit = number % 10;
    if (lastDigit === 1) {
      return "st";
    } else if (lastDigit === 2) {
      return "nd";
    } else if (lastDigit === 3) {
      return "rd";
    } else {
      return "th";
    }
  }

  convertToDays(duration: number, durationType: string) {
    if (durationType === "year") {
      return duration * 365;
    } else if (durationType === "month") {
      return duration * 30;
    } else if (durationType === "week") {
      return duration * 7;
    } else {
      return duration;
    }
  }

  calculateDifferenceInDays(startDate: Date, endDate: Date) {
    // Calculate the number of milliseconds in a day
    const oneDay = 1000 * 60 * 60 * 24;

    const differenceInTime = endDate.getTime() - startDate.getTime();
    return Math.abs(Math.floor(differenceInTime / oneDay))
  }

  // warn user if the payment plan will not be completed before the plan end date
  checkIfInstallmentsCompleteAfterPlan(startDate: Date, endDate: Date, numInstallments: number, duration: number, durationType: string) {
    const differenceInDays = this.calculateDifferenceInDays(startDate, endDate);
    const durationInDays = this.convertToDays(duration, durationType);
    return differenceInDays < durationInDays * numInstallments;
  }

  extractSvgFromXml(xmlContent: string): string {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(xmlContent, 'application/xml');
		const svgElement = xmlDoc.getElementsByTagName('svg')[0];
		if (svgElement) {
		  return new XMLSerializer().serializeToString(svgElement);
		}
		return '';
	}

  generateMagicLinksForEmbed(type:string,typeId:number,facilityId = null):string{
    const currentCenter = JSON.parse(localStorage.getItem("currentCenter"));
    const embedURL = currentCenter["website_url"] ? currentCenter["website_url"]+"#" : 'https://'+ currentCenter["webstore_url"];
    const regionId = JSON.parse(localStorage.getItem('currentCenter'))['region']['id'];
    const planCenterId = JSON.parse(localStorage.getItem('currentCenter'))['center_id'];
     switch(type){
      case 'CLASS':
        return  `${embedURL}/class-schedule/r/${regionId}?class=${typeId}&loc=${planCenterId}`;
      case 'APPOINTMENT':
        return `${embedURL}/appointment/r/${regionId}?id=${typeId}&facility=${facilityId}`;
      case 'COURSE':
        return `${embedURL}/course/buy/${typeId}/r/${regionId}/loc/${planCenterId}`;
      case 'PRICING':
        return `${embedURL}/pricing/buy/r/${regionId}/loc/${planCenterId}?plan_type=plan&id=${typeId}`; 
     }
  }
}
