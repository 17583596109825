import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { GeneralServices } from 'app/common/general-services';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit{

  @Input() firstPage: boolean = false;

  checkCookieStatus: boolean = false;

  constructor(private cdref: ChangeDetectorRef, private generalService: GeneralServices) { }

  ngOnInit(): void {
    this.checkCookieStatus =  JSON.parse(localStorage.getItem('acceptCookies'))
    this.generalService.setAllCookiesStatus(this.checkCookieStatus);
  }

  onSetCookieStatus(status){
    this.generalService.setCookie('accept_all_cookies', status);
    this.generalService.setAllCookiesStatus(status);
    localStorage.setItem("acceptCookies", JSON.stringify(status))
    this.checkCookieStatus = status
    this.cdref.detectChanges();
  }

  bookeDemo(){
    window.open('https://www.bookee.ai/demo/get-demo', "_blank");
  }
}
